export const environment = {
  production: false,
  baseUrl: 'http://dev01.umarta.local:8080',
  keycloak: {
    realm: 'schedules-test',
    url: 'http://dev01.umarta.local:9080/',
  },
  initOptions: {
    checkLoginIframe: false
  }
  // redirectUri: 'http://dev01.umarta.local'
};
